
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';

export default defineComponent({
  name: 'AccountActivated',
  mixins: [
    globals,
  ],
  created() {
    const vm = this;

    /* Try to set profile if authenticated, otherwise, redirect user to home */
    this.setProfile(this.$store);

    vm.init();
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleTopBar', true);
      this.$store.dispatch('toggleSideBar', false);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    goToHome(event: any) {
      event.preventDefault();

      this.$router.push({
        name: 'SegmentSelection',
      });
    },
    goToUserPanel(event: any) {
      event.preventDefault();

      this.$router.push({
        name: 'CustomerPanel',
      });
    },
    closeWindow() {
      window.close();
    },
  },
});
